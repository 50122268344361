import React from 'react';

import Button from '@atlaskit/button';
import BookIcon from '@atlaskit/icon/glyph/book';
import { Box, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

interface KnowledgeBadgeProps {
	name: string;
	url: string;
	onBadgeClick?: () => void;
}

const knowledgeBadgeStyles = xcss({
	display: 'inline-flex',
	border: `1px ${token('color.border')} solid`,
	borderRadius: 'border.radius.100',
});

const bookIconStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: 'color.background.accent.blue.subtlest',
	borderRadius: 'border.radius.050',
	height: '16px',
	width: '16px',
	padding: 'space.025',
	marginLeft: 'space.050',
});

const buttonLinkStyles = xcss({
	padding: 'space.025',
	marginLeft: 'space.025',
	marginRight: 'space.050',
});

export const KnowledgeBadge = ({ name, url, onBadgeClick = () => {} }: KnowledgeBadgeProps) => (
	<Box xcss={knowledgeBadgeStyles}>
		<Button
			href={url}
			appearance="subtle"
			onClick={() => {
				onBadgeClick();
			}}
			spacing="none"
			target="_blank"
			iconBefore={
				<Box xcss={bookIconStyles}>
					{/* TODO : https://product-fabric.atlassian.net/browse/DSP-20839 */}
					{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons */}
					<BookIcon label="Related topic" primaryColor={token('color.icon.brand')} size="small" />
				</Box>
			}
		>
			<Box xcss={buttonLinkStyles}>
				<Text>{name}</Text>
			</Box>
		</Button>
	</Box>
);
